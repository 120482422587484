import { createStore } from 'vuex'
import browse from './browse';
import edit from './edit';
import auth from './auth';
import API from '@/includes/API';
import { account_url, app_url, api_base_url } from '@/includes/helpers';
// API.base = api_base_url()
export default createStore({
  modules: {
    edit,
    browse,
    auth
  },
  state: {
    audio_controller: new Audio(),
    audio_controllers:{},
    global_volume: localStorage.getItem("filmstro.volume") || 1 ,
    video_source:"",
    video_element: false,
    video_file_info:{
      name:"",
      size:"",
    },

    user_favourites:[],
    show_loader:false,
    loading_message:'Loading...',
    login_modal_visible:false,
    pricing_modal_visible:false,
    pricing_modal_title:"Remove Watermark &amp; Unlock All Templates",
    pricing_modal_description:"All plans include 100% royalty free music cleared for social media. No copyright strikes. All projects covered worldwide &amp; for ever.",
    action_logs:[],
    seo_title:"Filmstro Web App",
    preferences_modal_visible:false,

    login_redirect_url: `${ account_url() }?ref=app_login&redirect=${app_url()}`,
    show_about_filmstro_modal:false,
    show_welcome_modal:false,
    show_tutorial_modal:false,
    show_premium_feature_modal:false,
    show_no_more_free_sessions_modal:false,
    show_flyout_sidebar:false,
    show_login_required_modal:false,
    show_license_this_track_modal:false,
    license_this_track:{},
    track_license_pricing:[],
    timeline_zoom:1,
    is_fetching_favourites:false,
    enable_debug_for_admin:false,
    show_shortcuts_keyboard_modal:false
  },
  mutations: {
    add_action_log(state,action){
      state.action_logs.push( {
        time:Math.floor(Date.now() / 1000),
        action:action
      } );
    },

    setSeoTitle( state,title ){
      state.seo_title = `${title} - Filmstro`;
      document.title = state.seo_title;
    },


    setVideoSource( state,source ){

      state.video_source = source;
    },
    setVideoFileInfo( state,info ){

      state.video_file_info = info;
    },
    setVideoElement( state,info ){

      state.video_element = info;
    },


    setUserFavourites( state,data ){
      state.user_favourites = data;
    },

    setUserSessions( state,data ){
      state.user_sessions = data;
    },
    setShowLoader( state,data ){
        state.show_loader = data;
    },
    setLoaderMessage( state,data ){
      state.loading_message = data;
      if( data.length ){
        // state.show_loader = true;
      }
    },
    setPricingModalVisible( state,data ){
      state.pricing_modal_visible = data;
    },
    setPricingModalTitle( state,data ){
      state.pricing_modal_title = data;
    },
    setPricingModalDescription( state,data ){
      state.pricing_modal_description = data;
    },
    setTimelineZoom(state,val){
      let volume = parseFloat( val )
      if( volume > 1){
        volume = ( volume / 100 )
      }
      window.zoom = volume;

      state.timeline_zoom = volume;
    },
    setGlobalVolume( state,volume ){
      volume = parseFloat( volume )
      if(volume >1){
        volume = (volume / 100)
      }
      // if(volume > 1){
      //   volume = 1
      // }
      if(volume <= 0){
        volume = 0;
      }

      state.global_volume = volume;
      if( state.audio_controller ){
        state.audio_controller.volume = volume;
      }
      localStorage.setItem("filmstro.volume", volume );
    },
    setLoginModalVisible( state,status ){
      state.login_modal_visible = status;
    },
    // setLoginRedirectURL( state,url ){
    //   state.login_redirect_url =  `${ account_url() }?ref=app_login&redirect="${app_url()}${url}"`
    // },
    setPreferencesModalVisible( state,status ){
      state.preferences_modal_visible = status;
    },
    setShowAboutFilmstroModal(state,value){
      state.show_about_filmstro_modal = value;
    },

    setShowWelcomeModal( state ,value ){
        state.show_welcome_modal = value;
    },
    setShowTutorialModal( state ,value ){
      state.show_tutorial_modal = value;
    },
    setShowPremiumFeatureModal( state ,value ){
      state.show_premium_feature_modal = value;
    },
    setShowNoMoreFreeSessionsModal( state ,value ){
      state.show_no_more_free_sessions_modal = value;
    },
    setShowFlyoutSideBar( state ,value ){
      state.show_flyout_sidebar = value;
    },
    setShowLoginRequiredModal( state ,value ){
      state.show_login_required_modal = value;
    },
    setShowShortcutsKeyboardModal( state ,value ){
      state.show_shortcuts_keyboard_modal = value;
    },
    setFlyoutContentView( state ,value ){
      state.flyout_content_view = value;
      if( value ){
        state.show_flyout_sidebar = true;
      }else{
        state.show_flyout_sidebar = false;
      }
    },
    setShowLicenseThisTrackModal(state,view){
      state.show_license_this_track_modal = view;
    },
    setTrackLicensePricing(state,pricing){
      state.track_license_pricing = pricing;
    },
    setLicenseThisTrack(state,pricing){
      state.license_this_track = pricing;
    },
    setIsFetchingFavourites(state,value){
      state.is_fetching_favourites = value;
    },
    setEnableDebugForFdmin(state,value){
      state.enable_debug_for_admin = value;
    }
  },
  actions: {

    // Sessions
    async fetchUserSessions( context,force=false ){

      let response = false;
      let user_id = context.state.auth.user.user_id;
      if(window.user_sessions_last_fetch + 5 > Math.floor( Date.now() / 1000) && !force){
        return;
      }
      // context.commit('setShowLoader',true);
      // context.commit('setLoaderMessage','Loading my sessions!');
      await API.post( `/get-user-sessions/${user_id}` ).then(data=>{
        response = data;
        context.commit('setUserSessions',data)
        window.user_sessions_last_fetch = Math.floor(Date.now() / 1000);
        // context.commit('setShowLoader',false);

      })
      return response;
    },


    // Favourites
    async toggleFavourite(context,theme_id){
      let _index = context.state.user_favourites.findIndex(f=>{return f.theme_id ==theme_id});
      // does not exit in our favourites
      if(_index<0){
        return await API.post(`/user-favourites/${context.getters.CURRENT_USER.user_id}`,{ theme_id:theme_id }).then(r=>{
          if( r.theme ){
            let favs = context.state.user_favourites;
            favs.push( r.theme );
            context.commit('setUserFavourites',favs);
          }
          return r;
        })
      }else{
        return await API.delete(`/remove-user-favourite/${context.getters.CURRENT_USER.user_id}`,{ theme_id:theme_id }).then(r=>{
          let favs = context.state.user_favourites;
          favs.splice(_index,1 );
          context.commit('setUserFavourites',favs);
          return r;
        })
      }
    },
    async fetchFavourites( context, update=false ){
      let user_id = context.getters.CURRENT_USER.user_id;
      let is_fetching = context.getters.is_fetching_favourites;

      if( is_fetching ) return;

      if(window.user_favourites_last_fetch + 2 > Math.floor(Date.now() / 1000) ){
        console.log('SHOULD NOT FETCH FAVOURITES AGAIN NOW, TRY AGIAN IN A FEW SECONDS!');
        return;
      }
      context.commit('setIsFetchingFavourites',true)
      return await API.post( `/get-user-favourites/${ user_id }` ).then(data=>{
        context.commit('setUserFavourites',data);
        if( update ){
          context.dispatch('maybeSetFavouritesAlbum');
        }
        window.user_favourites_last_fetch  = Math.floor(Date.now() / 1000);
        context.commit('setIsFetchingFavourites',false)
      }).catch(e=>{
        context.commit('setIsFetchingFavourites',false)
      })
    },
    async maybeSetFavouritesAlbum( context ){
      let favs = context.getters['favourite_album'];
      if( favs ){
        context.commit('browse/setSelectedAlbum', favs);
      }
    },
    async renameSession( context,data ){
      return await API.post( `/rename-user-session/${data.session_id}`, data ).then(response=>{
        return response;
      })
    },
    async deleteSession( context,data ){

      return await API.post( `/delete-user-session/${data.user_id}`, data ).then(response=>{
        return response;
      })
    },
    setLoginRedirectURL( state,url ){
      let _url = `${ account_url() }?ref=app_login&view=login`;
      if( typeof url =='object' ){
        _url +=  `&${new URLSearchParams( url ).toString()}`;
      }else{
        _url += `&redirect_to=${app_url()}${( encodeURIComponent( url )  )}`;
      }
      window.location.href = _url;
    },

    async downloadThemeAudioFile( context,  data  ){
      if( !context.getters.CURRENT_USER.user_id ){
        return false;
      }
      return await API.post(`/download-track`,{
        user_id: context.getters.CURRENT_USER.user_id,
        track_id:data.track_id,
        file_name:data.file_name
      });
    },

    maybeSetCollectionAndAlbumFromRoute( context, route ){

      if( route.params.collection){
        let collection = context.getters['browse/collections'].find(col=>{
          return col.name == route.params.collection;
        })
        if( collection ){
          // this.setSelectedCollection( collection );
          context.commit('browse/setSelectedCollection',collection)

          if( typeof collection !=='undefined' && route.params.album){
            let album = collection.albums.find(al=>{
              return al.name == route.params.album;
            })
            if( album ){
              context.commit('browse/setSelectedAlbum',album)
              if(album.tracks){
                context.commit('browse/setMusicCards',album.tracks)
              }
            }
          }
          if( route.query && route.query.composer ){
            context.commit('browse/setComposerBySlug',route.query.composer );
          }
        }
      }
    },
    async track_user_action(context,data){
      if(!context.getters.CURRENT_USER.user_id){
        return false;
      }
      if( !data.action_name || !data.action_data ){
        return false;
      }

      return await API.post(`/user-action/${context.getters.CURRENT_USER.user_id}`, { method: 'POST', ...data })
    }




  }, //actions

  getters:{
    audio_controller( state ){
      return state.audio_controller;
    },
    audio_controllers( state ){
      return state.audio_controllers;
    },
    timeline_zoom( state ){
      return state.timeline_zoom;
    },
    global_volume( state ){
      return state.global_volume;
    },
    video_source( state ){
      return state.video_source;
    },
    video_file_info( state ){
      return state.video_file_info;
    },
    user_sessions( state ){
      return state.user_sessions;
    },
    user_favourites( state ){
      if(state.auth.user && state.user_favourites){
        return state.user_favourites;
      }
      return [];
    },
    favourite_album(state){
      let favs = state.user_favourites.filter(f=>f.track && f.track.id).map(f=>f.track);
      return {
        "id": 82235,
        "title": "Favourites",
        "name": "favourites",
        "icon": false,
        "image": "https://filmstro.com/wp-content/uploads/2024/06/favourites_banner-1.png",
        "background_image": "https://filmstro.com/wp-content/uploads/2024/06/favourites_banner-1.png",
        "thumbnail": "https://filmstro.com/wp-content/uploads/2024/06/favourites_thumb-1.png",
        "description": "Your favourites. Simply click the favourites icon to add or remove tracks from this list. ",
        "search_terms": [],
        "tracks": favs,
        "collection": "favourites"
      }
    },
    is_in_user_favourites:(state)=>(theme_id)=>{

      if(state.auth.user && state.user_favourites){
        let _index = state.user_favourites.findIndex(f=>{
          return f.theme_id == theme_id;
        });
        return _index>=0;
      }
      return false;
    },
    user_has_active_license:( state )=>{
      return state.auth.user && state.auth.user.active_license;
    },
    user_can_access_theme: (state,getters) => ( theme ) => { // get the parameter

      let access = getters.user_has_active_license;
      if( !state.auth.user.user_id ){
        return false;
      }

      let access_to_themes = state.auth.user.access_to_themes;
      if( access_to_themes && access_to_themes.length && !access ){
        let _index = access_to_themes.findIndex(  _theme => _theme == theme );
        access = _index >=0 ;
      }
      return access;
    },

    show_loader:( state )=>{
      return state.show_loader;
    },
    loading_message:( state )=>{
      return state.loading_message;
    },
    login_modal_visible:( state )=>{
      return state.login_modal_visible;
    },
    preferences_modal_visible:( state )=>{
      return state.preferences_modal_visible;
    },
    pricing_modal_visible:( state )=>{
      return state.pricing_modal_visible;
    },
    pricing_modal_description:( state )=>{
      return state.pricing_modal_description;
    },
    pricing_modal_title:( state )=>{
      return state.pricing_modal_title;
    },
    video_element:( state )=>{
      return state.video_element;
    },
    seo_title:(state)=>{
      return state.seo_title ;
    },


    login_redirect_url(state){
      return state.login_redirect_url;
    },
    show_about_filmstro_modal(state){
      return state.show_about_filmstro_modal;
    },
    show_welcome_modal(state){
      return   state.show_welcome_modal
     },
    show_tutorial_modal(state){
      return   state.show_tutorial_modal
     },
    show_premium_feature_modal(state){
      return   state.show_premium_feature_modal
     },
    show_no_more_free_sessions_modal(state){
      return   state.show_no_more_free_sessions_modal
     },
    show_flyout_sidebar(state){
      return   state.show_flyout_sidebar
     },
    flyout_content_view(state){
      return   state.flyout_content_view
     },
    show_login_required_modal(state){
      return   state.show_login_required_modal
     },
    show_shortcuts_keyboard_modal(state){
      return   state.show_shortcuts_keyboard_modal
     },
     show_license_this_track_modal( state ){
      return state.show_license_this_track_modal;
    },
    track_license_pricing( state ){
      return state.track_license_pricing;
    },
    is_fetching_favourites( state ){
      return state.is_fetching_favourites;
    },
    enable_debug_for_admin(state){
      return state.enable_debug_for_admin && state.auth.user && state.auth.user.is_admin;
    }
  },

})
